import React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WorkIcon from "@mui/icons-material/Work";
import SellIcon from "@mui/icons-material/Sell";
import GroupsIcon from "@mui/icons-material/Groups";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useNavigate } from "react-router-dom";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FeaturesSegegration from "../../hocs/FeaturesSegegration";
import UserRoles from "../../utilities/userRoles";
import CloudDownload from "@mui/icons-material/CloudDownload";

const Sidelist = ({ toggleDrawer }) => {
  const navigate = useNavigate();

  const routing = (route) => () => {
    navigate(route);
    toggleDrawer();
  };

  return (
    <List
      sx={{ ml: 2, width: "90%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Welcome to BOOM VIBER
        </ListSubheader>
      }
    >
      {/* <ListItemButton onClick={routing("analytics")}>
        <ListItemIcon>
          <BarChartRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary="Analytics"
          primaryTypographyProps={{ fontWeight: 700 }}
        />
      </ListItemButton> */}

      <ListItemButton onClick={routing("company")}>
        <ListItemIcon>
          <WorkIcon />
        </ListItemIcon>
        <ListItemText
          primary="Company"
          primaryTypographyProps={{ fontWeight: 700 }}
        />
      </ListItemButton>

      <FeaturesSegegration roles={[UserRoles.OPERATION]}>
        <ListItemButton onClick={routing("viberprofile")}>
          <ListItemIcon>
            <StorefrontIcon />
          </ListItemIcon>
          <ListItemText
            primary="Viber Profile"
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
      </FeaturesSegegration>

      <FeaturesSegegration roles={[UserRoles.OPERATION]}>
        <ListItemButton onClick={routing("packages")}>
          <ListItemIcon>
            <SellIcon />
          </ListItemIcon>
          <ListItemText
            primary="Packages"
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
      </FeaturesSegegration>

      <FeaturesSegegration roles={[UserRoles.OPERATION]}>
        <ListItemButton onClick={routing("phonegroup")}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Phone Group"
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
      </FeaturesSegegration>

      <FeaturesSegegration roles={[UserRoles.OPERATION, UserRoles.BILLING]}>
        <ListItemButton onClick={routing("orders")}>
          <ListItemIcon>
            <RequestPageIcon />
          </ListItemIcon>
          <ListItemText
            primary="Orders"
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
      </FeaturesSegegration>

      <FeaturesSegegration roles={[UserRoles.OPERATION]}>
        <ListItemButton onClick={routing("annoucement")}>
          <ListItemIcon>
            <CampaignIcon />
          </ListItemIcon>
          <ListItemText
            primary="Announcement"
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
      </FeaturesSegegration>

      <FeaturesSegegration roles={[UserRoles.OPERATION]}>
        <ListItemButton onClick={routing("advertisement")}>
          <ListItemIcon>
            <AdUnitsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Advertisement"
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
      </FeaturesSegegration>

      <FeaturesSegegration>
        <ListItemButton onClick={routing("users")}>
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Users"
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
      </FeaturesSegegration>
      <FeaturesSegegration>
        <ListItemButton onClick={routing("reports")}>
          <ListItemIcon>
            <CloudDownload/>
          </ListItemIcon>
          <ListItemText
            primary="Reports"
            primaryTypographyProps={{ fontWeight: 700 }}
          />
        </ListItemButton>
      </FeaturesSegegration>
    </List>
  );
};

export default Sidelist;
