import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../hooks";

const AdjustUserLimit = ({
  user_limit,
  company_id,
  open,
  handleClose,
  handleDialogOnClick,
}) => {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const [data, setData] = useState({ company_id: "", user_limit: "" });

  const { mutate } = useMutation(
    () => {
      return api.put(`/api/company/i/user/limit`, data);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`company-details_${company_id}`);
        handleClose();
        setData({ company_id: "", user_limit: "" });
      },
    }
  );

  useEffect(() => {
    setData({ user_limit, company_id });
  }, [user_limit, company_id]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogOnClick}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {"Adjust User Limit"}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            marginTop: "20px",
            marginBottom: "8px",
            color: "secondary.light",
          }}
        >
          Limit
        </Typography>
        <TextField
          name="user_limit"
          type="number"
          value={data.user_limit}
          onChange={(e) =>
            setData({ ...data, [e.target.name]: Number(e.target.value) })
          }
          label=""
          size="small"
          sx={{
            width: "100%",
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
            },
          }}
        />

        <Button
          sx={{ mt: 1 }}
          fullWidth
          onClick={() => mutate()}
          disableElevation
          size="small"
          variant="contained"
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AdjustUserLimit;
