import { Box, Button, Divider, Grid, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "../../hooks";
import BackButton from "../../components/common/BackButton";
import { getDateTime } from "../../utilities/getDateTime";
import ComfirmDialog from "../../components/common/ConfirmDialog";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";
import { formatDuration } from "../../utilities/formatDuration";

const PackageDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const openAlert = useSetRecoilState(withAlert);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ package_id: "", action: "" });

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const { data: res } = useQuery(`package-details_${id}`, () => {
    return api.get(`/api/package/i/details/${id}`);
  });

  const { mutate } = useMutation(
    () => {
      return api.put(`/api/package/i/status/${id}?status=${data.action}`);
    },
    {
      onSuccess: (res) => {
        openAlert({
          status: res.status,
          detail:
            typeof res.data.message === "string"
              ? res.data.message
              : res.data.message[0],
        });
        queryClient.invalidateQueries(`package-details_${id}`);
      },
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 2,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        variant="dense"
        disableGutters={true}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <BackButton backFunction={() => navigate(-1)} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Details
          </Typography>
        </Box>

        <Box
          sx={{ display: res?.status === undefined ? "none" : "flex", gap: 1 }}
        >
          <Button
            variant="outlined"
            color="success"
            size="small"
            sx={{
              display: res?.data?.is_active === true ? "none" : "inline-block",
            }}
            onClick={() => {
              setData({
                package_id: res?.data.id,
                action: !res?.data?.is_active,
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Activate</Typography>
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="small"
            sx={{
              display: res?.data?.is_active === false ? "none" : "inline-block",
            }}
            onClick={() => {
              setData({
                package_id: res?.data.id,
                action: !res?.data?.is_active,
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Deactivate</Typography>
          </Button>
        </Box>
      </Toolbar>

      <Divider sx={{ mt: "12px", mb: "16px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Package ID
          </Typography>
          <Typography variant="h6">{res?.data?.id}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Package Name
          </Typography>
          <Typography variant="h6">{res?.data?.name}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Description
          </Typography>
          <Typography variant="h6">{res?.data?.description}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            status
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: res?.data?.is_active ? "green" : "red" }}
          >
            {res?.data?.is_active ? "Activated" : "Deactivated"}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mt: "12px", mb: "16px" }} />
      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Price
          </Typography>
          <Typography variant="h6">{res?.data?.price}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Tax
          </Typography>
          <Typography variant="h6">{res?.data?.credit}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Type
          </Typography>
          <Typography variant="h6">{res?.data?.type}</Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Duration
          </Typography>
          <Typography variant="h6">
            {formatDuration(res?.data?.duration)}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: "12px", mb: "16px" }} />

      <Grid container sx={{ width: "100%" }} spacing={1}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Created At
          </Typography>
          <Typography variant="h6">
            {getDateTime(res?.data?.created_at)}
          </Typography>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Typography variant="subtitle2" sx={{ color: "grey" }}>
            Updated At
          </Typography>
          <Typography variant="h6">
            {getDateTime(res?.data?.updated_at)}
          </Typography>
        </Grid>
      </Grid>
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={mutate}
      />
    </Box>
  );
};

export default PackageDetails;
