import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  DialogContent,
  useMediaQuery,
  Box,
  TextField,
  FormGroup,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAxios } from "../../hooks";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Permissions from "../../utilities/permission";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCompanyUser({ open, handleClose }) {
  const isSmallToMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const { id } = useParams();

  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const openAlert = useSetRecoilState(withAlert);

  const initialData = {
    user_name: "",
    user_email: "",
    user_password: "",
    confirm_user_password: "",
    permission: {},
  };

  const [data, setData] = useState(initialData);

  const handleOnChange = (key) => (e) => {
    if (key) {
      setData((prevFormData) => ({
        ...prevFormData,
        permission: {
          ...prevFormData.permission,
          [key]: e.target.checked ?? false,
        },
      }));
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const { isLoading, mutate } = useMutation(
    (data) => {
      return api.post(`/api/company/${id}/user/create`, data);
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setData(initialData);
          queryClient.invalidateQueries("company-user_status");
          handleClose();
        }
      },
    }
  );

  const handleRegister = (e) => {
    e.preventDefault();
    if (data.user_password !== data.confirm_user_password) {
      openAlert({
        status: 400,
        detail: "Passwords do not match.",
      });
    } else {
      /**
       * check new user have permission or not
       */
      if (!Object.keys(data.permission).length) {
        openAlert({
          status: 400,
          detail: "User should have some permission!",
        });
        return;
      }

      /**
       * remove all false permission properties
       */
      const filteredPermission = Object.fromEntries(
        Object.entries(data.permission).filter(
          ([key, value]) => value !== false
        )
      );

      /**
       * remove all permission properties if Admin permission selected
       */
      if (filteredPermission[Permissions.ADMINISTRATOR]) {
        for (const key in filteredPermission) {
          if (key !== Permissions.ADMINISTRATOR) {
            delete filteredPermission[key];
          }
        }
      }
      mutate({ ...data, permission: JSON.stringify(filteredPermission) });
    }
  };

  return (
    <Dialog
      open={open}
      fullScreen={isSmallToMediumScreen}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { height: { md: 600 }, minWidth: { lg: 1000, md: 800 } },
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Add New Company User
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box sx={{ marginBottom: "1em" }}>
          <Typography variant="subtitle2" color="text.secondary">
            Create new and manage company user's accounts.
          </Typography>
        </Box>
        <Box component="form" onSubmit={handleRegister}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: 2, lg: 4 },
            }}
          >
            <Box
              flex={0.4}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  color: "secondary.light",
                }}
              >
                Name
              </Typography>
              <TextField
                name="user_name"
                value={data.user_name}
                onChange={handleOnChange()}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  color: "secondary.light",
                }}
              >
                Email
              </Typography>
              <TextField
                name="user_email"
                value={data.user_email}
                onChange={handleOnChange()}
                size="small"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  color: "secondary.light",
                }}
              >
                Password
              </Typography>
              <TextField
                name="user_password"
                value={data.user_password}
                onChange={handleOnChange()}
                size="small"
                type="password"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  color: "secondary.light",
                }}
              >
                Confirm Password
              </Typography>
              <TextField
                name="confirm_user_password"
                value={data.confirm_user_password}
                onChange={handleOnChange()}
                size="small"
                type="password"
                sx={{
                  border: "none",
                  borderRadius: 1,
                  "& .MuiInputBase-root": {
                    backgroundColor: "#F0F0F0",
                    borderRadius: 1,
                    outline: "none",
                    "&:hover": {
                      backgroundColor: "#F0F0F0",
                    },
                  },
                }}
              />
            </Box>

            <Box flex={0.7}>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  color: "secondary.light",
                }}
              >
                Permission
              </Typography>
              <FormGroup sx={{ mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      sx={{ py: "5px" }}
                      onClick={handleOnChange(Permissions.ADMINISTRATOR)}
                    />
                  }
                  label={
                    <Typography variant="subtitle2">Administrator</Typography>
                  }
                />
                <Box sx={{ pl: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{ py: "5px" }}
                        //   checked={data.permission.Permissions.SMS_SERVICE ?? false}
                        onClick={handleOnChange(Permissions.SMS_SERVICE)}
                      />
                    }
                    label={<Typography variant="subtitle2">Message</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{ py: "5px" }}
                        //   checked={
                        //     data.permission.Permissions.CHAT_SERVICE ?? false
                        //   }
                        onClick={handleOnChange(Permissions.CHAT_SERVICE)}
                      />
                    }
                    label={<Typography variant="subtitle2">Chats</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{ py: "5px" }}
                        //   checked={
                        //     data.permission.Permissions.TOOL_SERVICE ?? false
                        //   }
                        onClick={handleOnChange(Permissions.TOOL_SERVICE)}
                      />
                    }
                    label={<Typography variant="subtitle2">Tool</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{ py: "5px" }}
                        //   checked={
                        //     data.permission.Permissions.DATA_SERVICE ?? false
                        //   }
                        onClick={handleOnChange(Permissions.DATA_SERVICE)}
                      />
                    }
                    label={<Typography variant="subtitle2">Data</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{ py: "5px" }}
                        onClick={handleOnChange(Permissions.USER_SERVICE)}
                      />
                    }
                    label={<Typography variant="subtitle2">User</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{ py: "5px" }}
                        onClick={handleOnChange(Permissions.TOP_UP_SERVICE)}
                      />
                    }
                    label={<Typography variant="subtitle2">Top up</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{ py: "5px" }}
                        onClick={handleOnChange(Permissions.APIKEY_SERVICE)}
                      />
                    }
                    label={<Typography variant="subtitle2">API Key</Typography>}
                  />
                </Box>
              </FormGroup>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              position: "absolute",
              bottom: 16,
              right: 16,
              width: "calc(100% - 32px)",
            }}
          >
            <LoadingButton
              loading={isLoading}
              size="large"
              variant="contained"
              disableElevation
              type="submit"
            >
              Create
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
