import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { withAlert } from "../../recoil/snackbar";
import { useSetRecoilState } from "recoil";
import { useMutation, useQueryClient } from "react-query";
import useAxios from "../../hooks/useAxios";

const BindServiceIdDialog = ({
  propData,
  open,
  handleDialogOnClick,
  page,
  rowsPerPage,
}) => {
  const openAlert = useSetRecoilState(withAlert);
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();

  const [data, setData] = useState({
    viberprofile_name: "",
    service_id: "",
    viberprofile_id: "",
    two_way: false,
  });

  useEffect(() => {
    setData((prevState) => {
      return { ...prevState, ...propData };
    });
  }, [propData]);

  const { mutate } = useMutation(
    async () => {
      return await api.put(`/api/viberprofile/i/${data.viberprofile_id}`, {
        viberprofile_name: data.viberprofile_name,
        service_id: Number(data.service_id),
        two_way: data.two_way,
      });
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setData({
            viberprofile_name: "",
            service_id: "",
            viberprofile_id: "",
            two_way: false,
          });
          queryClient.invalidateQueries(
            `viberprofile-list_accepted_?page=${page}&rowsPerPage=${rowsPerPage}`
          );
          handleDialogOnClick();
        }
      },
    }
  );

  const handleCheckPasswords = () => {
    if (data.viberprofile_name !== "" && data.service_id !== "") {
      if (data.service_id.length < 6) {
        openAlert({
          status: 400,
          detail: "Service ID must have 6 digits",
        });
      }
      mutate();
    } else {
      openAlert({
        status: 400,
        detail: "Please fill all fields!",
      });
    }
  };

  const handleOnChange = (e) => {
    if (e.target.name === "service_id" && e.target.value.length > 6) return;
    setData((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogOnClick}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <DialogTitle id="add-service-id-dialog">
        {"Add Service ID"}
        <IconButton
          aria-label="close"
          onClick={handleDialogOnClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          m: 2,
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            color: "secondary.light",
          }}
        >
          Viber Profile Name
        </Typography>
        <TextField
          required={true}
          name="viberprofile_name"
          size="small"
          label=""
          type="text"
          value={data.viberprofile_name}
          onChange={handleOnChange}
          fullWidth
          margin="normal"
          sx={{
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />

        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{
            color: "secondary.light",
          }}
        >
          Service ID
        </Typography>
        <TextField
          required={true}
          name="service_id"
          size="small"
          type="number"
          value={data.service_id}
          onChange={handleOnChange}
          fullWidth
          margin="dense"
          sx={{
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />

        <FormGroup aria-label="twoway" row>
          <FormControlLabel
            name="two_way"
            value={data.two_way}
            onChange={(e) =>
              setData({ ...data, [e.target.name]: !data.two_way })
            }
            sx={{ ml: 0 }}
            control={<Switch color="primary" />}
            label="Two way:"
            labelPlacement="start"
          />
        </FormGroup>

        <Button
          sx={{ mt: 1 }}
          fullWidth
          onClick={handleCheckPasswords}
          disableElevation
          size="small"
          variant="contained"
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default BindServiceIdDialog;
