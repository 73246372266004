import { Box, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ReportCard = () => {
  const navigate = useNavigate();
  const routing = (route) => () => navigate(route);
  return (
    <>
      <Box>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{ my: "1rem", fontSize: "1.2rem" }}
        >
          Reports
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, mt: "1.5rem" }}>
        {[
          {
            cardHeading: "Monthly Usage Report",
            cardDescription: "To generate monthly usage reports.",
            cardRoute: "monthly-report",
          },
        ].map(({ cardHeading, cardDescription, cardRoute }) => (
          <Card
            key={cardRoute}
            variant="elevation"
            sx={{
              width: "300px",
              borderRadius: "0.5rem",
              padding: "0.5rem",
              cursor: "pointer",
            }}
            onClick={routing(cardRoute)}
          >
            <CardContent>
              <Typography
                color="primary"
                sx={{ fontWeight: "bold", fontSize: "1rem", mb: "0.5rem" }}
              >
                {cardHeading}
              </Typography>
              <Typography>{cardDescription}</Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};

export default ReportCard;
