import * as React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AppBar from "../components/common/AppBar";
import { Company } from "./company";
import Analytics from "./analytics/Analytics";
import Viberprofile from "./viberprofile/Viberprofile";
import Phonegroup from "./phonegroup/Phonegroup";
import { styled } from "@mui/material/styles";
import SideDrawer from "../components/common/SideDrawer";
import WorkIcon from "@mui/icons-material/Work";
import SellIcon from "@mui/icons-material/Sell";
import Packages from "./packages/Packages";
import Order from "./order/Order";
import Users from "./users/Users";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupsIcon from "@mui/icons-material/Groups";
import CampaignIcon from "@mui/icons-material/Campaign";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Profile from "./profile/Profile";
import Announcement from "./annoucement/Annoucement";
import Advertisement from "./advertisement/Advertisements";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FeaturesSegegration from "../hocs/FeaturesSegegration";
import UserRoles from "../utilities/userRoles";
import Reports from "./reports/Reports";
import MonthlyReport from "./reports/MonthlyReport";

const drawerWidth = 300;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => setOpen(!open);
  const routing = (route) => () => navigate(route);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <AppBar toggleDrawer={toggleDrawer} />
      <Toolbar />
      <SideDrawer open={open} toggleDrawer={toggleDrawer} />
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          sx={{
            flex: "3 3 10%",
            padding: "1rem",
            display: { xs: "none", lg: "block" },
          }}
        >
          <List component="div" disablePadding>
            {[
              // {
              //   icon: <BarChartRoundedIcon />,
              //   route: "analytic",
              //   label: "Analytic",
              // },
              {
                icon: <WorkIcon />,
                route: "company",
                label: "Company",
                isPublic: true,
              },
              {
                icon: <StorefrontIcon />,
                route: "viberprofile",
                label: "Viber Profile",
                roles: [UserRoles.OPERATION],
              },
              {
                icon: <SellIcon />,
                route: "packages",
                label: "Packages",
                roles: [UserRoles.OPERATION],
              },
              {
                icon: <GroupsIcon />,
                route: "phonegroup",
                label: "Phone Group",
                roles: [UserRoles.OPERATION],
              },
              {
                icon: <RequestPageIcon />,
                route: "orders",
                label: "Orders",
                roles: [UserRoles.BILLING, UserRoles.OPERATION],
              },
              {
                icon: <CampaignIcon />,
                route: "annoucement",
                label: "Announcement",
                roles: [UserRoles.OPERATION],
              },
              {
                icon: <AdUnitsIcon />,
                route: "advertisement",
                label: "Advertisement",
                roles: [UserRoles.OPERATION],
              },
              {
                icon: <ManageAccountsIcon />,
                route: "users",
                label: "Users",
              },
              {
                icon: <CloudDownloadIcon />,
                route: "reports",
                label: "Reports",
              },
            ].map(({ icon, route, label, roles, isPublic }) => (
              <FeaturesSegegration
                key={route}
                roles={roles}
                isPublic={isPublic}
              >
                <ListItemButton
                  selected={location.pathname.includes(route)}
                  sx={{
                    height: "48px",
                    mb: "1rem",
                    borderRadius: "0.5rem",
                    // "&:hover": {
                    //   color: "primary.main",
                    // },
                    // "&.Mui-selected": {
                    //   backgroundColor: "#00BFFF",
                    // },
                  }}
                  onClick={routing(route)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                      mr: 1,
                      color: location.pathname.includes(route)
                        ? "primary.main"
                        : "#757575",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    primaryTypographyProps={{
                      fontWeight: 700,
                      color: location.pathname.includes(route)
                        ? "primary.main"
                        : "#757575",
                    }}
                  />
                </ListItemButton>
              </FeaturesSegegration>
            ))}
          </List>
        </Box>
        <Main
          sx={{
            flex: "10 10 70%",
            display: "flex",
            height: "92vh",
            borderRadius: "0.8rem",
            boxShadow: "1px 1px 20px rgba(51, 51, 51, 0.08)",
            justifyContent: "start",
            alignItems: "flex-start",
            overflow: "auto",
          }}
        >
          <Routes>
            <Route path="analytics" element={<Analytics />} />
            <Route path="company/*" element={<Company />} />
            <Route path="viberprofile/*" element={<Viberprofile />} />
            <Route path="packages/*" element={<Packages />} />
            <Route path="phonegroup/*" element={<Phonegroup />} />
            <Route path="orders/*" element={<Order />} />
            <Route path="users/*" element={<Users />} />
            <Route path="reports/*" element={<Reports />} />
            <Route path="monthly-report" element={<MonthlyReport/>}/>
            <Route path="profile/*" element={<Profile />} />
            <Route path="annoucement/*" element={<Announcement />} />
            <Route path="advertisement/*" element={<Advertisement />} />
            <Route path="*" element={<Navigate to={"company"} replace />} />
          </Routes>
        </Main>
      </Box>
    </Box>
  );
}

export default Dashboard;
