import { useState } from "react";
import {
  Avatar,
  Box,
  CssBaseline,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import authAtom from "../recoil/auth";
import { withAlert } from "../recoil/snackbar";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

export default function SignInSide() {
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authAtom);
  const openAlert = useSetRecoilState(withAlert);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const { isLoading, mutate } = useMutation(
    (data) => {
      return axios.post(
        `${process.env.REACT_APP_SERVICE_BASE_URL}/api/authentication/i/login`,
        data,
        {
          validateStatus: function (status) {
            return status <= 500;
          },
        }
      );
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setAuth(res.data);
          localStorage.setItem("boomviber-i-auth", JSON.stringify(res.data));
          navigate("/");
        } else {
          openAlert({
            status: res.status,
            detail:
              typeof res.data.message === "string"
                ? res.data.message
                : res.data.message[0],
          });
        }
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(data);
  };

  return (
    <Box
      component="main"
      sx={{ height: "100vh", backgroundColor: "#F4F7FA", display: "flex" }}
    >
      <CssBaseline />
      <Box
        flex={0.5}
        sx={{
          backgroundColor: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Container
        maxWidth="xs"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
        }}
      >
        <Avatar
          sx={{ m: 1, bgcolor: "primary.main", width: "60px", height: "60px" }}
        >
          <LockOutlinedIcon sx={{ width: "40px", height: "40px" }} />
        </Avatar>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{ marginTop: "20px", color: "secondary.main" }}
          >
            Email
          </Typography>
          <TextField
            value={data.email}
            onChange={handleOnChange}
            margin="normal"
            size="small"
            required
            fullWidth
            name="email"
            autoComplete="email"
            autoFocus
            sx={{
              backgroundColor: "white",
              borderRadius: "4px",
              "& .MuiInputBase-input": {
                color: "black",
                fontSize: "14px",
              },
              "& .MuiInputBase-root:hover .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiInputBase-root.Mui-focused .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
          />

          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{ marginTop: "10px", color: "secondary.main" }}
          >
            Password
          </Typography>
          <TextField
            value={data.password}
            onChange={handleOnChange}
            margin="normal"
            size="small"
            required
            fullWidth
            name="password"
            type="password"
            autoComplete="password"
            autoFocus
            sx={{
              backgroundColor: "white",
              borderRadius: "4px",
              "& .MuiInputBase-input": {
                color: "black",
                fontSize: "14px",
              },
              "& .MuiInputBase-root:hover .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiInputBase-root.Mui-focused .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
          />

          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 4,
              mb: 2,
              backgroundColor: "primary.main",
              color: "white",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "secondary.main", color: "#000" },
            }}
            size="large"
          >
            Sign In
          </LoadingButton>
        </Box>
      </Container>
      <Box
        flex={0.5}
        sx={{
          backgroundColor: "secondary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    </Box>
  );
}
