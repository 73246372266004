const Permissions = {
  ORIGINAL_ADMINISTRATOR: "OA",
  ADMINISTRATOR: "A",
  SMS_SERVICE: "SS",
  CHAT_SERVICE: "CS",
  TOOL_SERVICE: "TS",
  DATA_SERVICE: "DS",
  USER_SERVICE: "US",
  TOP_UP_SERVICE: "TUS",
  APIKEY_SERVICE: "AKS",
};

export default Permissions;
