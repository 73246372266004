import React, { useCallback, useMemo, useState } from "react";
import { useAxios } from "../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ComfirmDialog from "../common/ConfirmDialog";
import { getDateTime } from "../../utilities/getDateTime";
import { useParams } from "react-router-dom";
import NoRowOverlay from "../common/NoRowOverlay";
import { formatDuration } from "../../utilities/formatDuration";

const CompanyPackageList = ({ handleOnSelecting }) => {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ company_id: "", action: "" });

  const [page] = useState(0);
  const [pageSize] = useState(25);

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const { data: res, isLoading } = useQuery(`package-list_active_${id}`, () => {
    return api.get(`/api/company/i/${id}`);
  });

  const { mutate } = useMutation(
    () => {
      return api.delete(`/api/company/i/package/${data.company_id}`);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(
          `company-package-list_active_page=${page}_rowsPerPage=${pageSize}`
        );
        queryClient.invalidateQueries(`package-list_active_${id}`);
      },
    }
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      {
        field: "name",
        headerName: "Package Name",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.package.name,
      },
      {
        field: "price",
        headerName: "Price",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.package.price,
      },
      {
        field: "tax",
        headerName: "Tax",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.package.tax,
      },
      {
        field: "type",
        headerName: "Type",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.package.type,
      },
      {
        field: "duration",
        headerName: "Duration",
        minWidth: 100,
        flex: 1,
        valueGetter: (params) => params.row.package.duration,
        valueFormatter: (params) => formatDuration(params.value),
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => {
              setData({
                company_id: params.id,
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Remove</Typography>
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <DataGrid
        columns={columns}
        rows={res?.data ?? []}
        loading={isLoading}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
      />
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={mutate}
      />
    </>
  );
};

export default CompanyPackageList;
