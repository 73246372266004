import React from "react";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import ReportCard from "../../components/reports/ReportCard";
import MonthlyReport from "./MonthlyReport";

const Reports = () => {
  return (
    <Box sx={{ padding: "2rem" }}>
      <Routes>
        <Route path="/" element={<ReportCard />} />
        <Route path="monthly-report" element={<MonthlyReport />} />
      </Routes>
    </Box>
  );
};

export default Reports;
