import { useCallback, useMemo, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAxios } from "../../hooks";
import { Button, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../../recoil/snackbar";
import ComfirmDialog from "../common/ConfirmDialog";
import { getDateTime } from "../../utilities/getDateTime";
import NoRowOverlay from "../common/NoRowOverlay";

export default function InternalUsersList({ handleOnSelecting }) {
  const api = useAxios();
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState(false);
  const openAlert = useSetRecoilState(withAlert);
  const [data, setData] = useState({ user_id: "", action: null });

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const { data: res, isLoading } = useQuery(`internal-user_list`, () => {
    return api.get(`/api/user/i/list`);
  });

  const { mutate } = useMutation(
    () => {
      return api.put(
        `/api/user/i/status/${data.user_id}?status=${data.action}`
      );
    },
    {
      onSuccess: (res) => {
        openAlert({
          status: res.status,
          detail:
            typeof res.data.message === "string"
              ? res.data.message
              : res.data.message[0],
        });
        queryClient.invalidateQueries(`internal-user_list`);
      },
    }
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      { field: "name", headerName: "Name", minWidth: 100, flex: 1 },
      {
        field: "email",
        headerName: "Email",
        width: 150,
        minWidth: 100,
        flex: 1,
      },
      { field: "role", headerName: "Role", minWidth: 100, flex: 1 },
      {
        field: "is_active",
        headerName: "Is Active",
        minWidth: 100,
        flex: 1,
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color={params.row.is_active ? "error" : "success"}
            size="small"
            sx={{
              height: "30px",
              width: "100px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setData({
                user_id: params.id,
                action: !params.row.is_active,
              });
              handleOpen();
            }}
          >
            <Typography sx={{ fontSize: "11px", lineHeight: 1 }}>
              {params.row.is_active ? "Deactivate" : "Activate"}
            </Typography>
          </Button>
        ),
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <DataGrid
        columns={columns}
        rows={res?.data ?? []}
        loading={isLoading}
        checkboxSelection
        pagination
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={pageSize}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setPageSize(v)}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
      />
      <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={mutate}
      />
    </>
  );
}
