import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DragAndDropUpload from "../../components/common/DragAndDropUpload";
import { useState } from "react";
import { parse } from "csv-parse/browser/esm";
import { useNavigate, useParams } from "react-router-dom";
import { withAlert } from "../../recoil/snackbar";
import { useSetRecoilState } from "recoil";
import { useMutation, useQuery } from "react-query";
import useAxios from "../../hooks/useAxios";
import { LoadingButton } from "@mui/lab";
import BackButton from "../../components/common/BackButton";
import exportFromJSON from "export-from-json";

const CreateNewPhoneGroup = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const api = useAxios({ autoSnackbar: true });
  const openAlert = useSetRecoilState(withAlert);
  const [phoneGroupCsv, setPhoneGroupCsv] = useState(null);
  const [data, setData] = useState({ name: "", description: "" });

  const isUpdateForm = !!id;

  const handleSubmit = async () => {
    if (isUpdateForm) {
      // Handle update form submission (name and description)
      const formData = {
        name: data.name,
        description: data.description,
      };

      editPhonegroup(formData); // Use editPhonegroup mutation for update
    } else {
      // Handle add new form submission (with phone number file)
      if (phoneGroupCsv) {
        const reader = new FileReader();
        reader.readAsText(phoneGroupCsv);

        reader.onload = async (event) => {
          const actualData = event.target.result;
          try {
            const isValid = await processFileData(actualData);
            if (isValid && data.name !== "") {
              const formData = new FormData();
              formData.append("file", phoneGroupCsv);
              formData.append("name", data.name);
              formData.append("description", data.description);
              addNew(formData); // Use addNew mutation for add new
            } else {
              openAlert({
                status: 400,
                detail: "Name should not be empty",
              });
            }
          } catch (error) {
            openAlert({
              status: 400,
              detail: error,
            });
          }
        };
      } else {
        openAlert({
          status: 400,
          detail: "Phone Number file should not be empty!",
        });
      }
    }
  };

  const handleOnChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const { isLoading, mutate: addNew } = useMutation(
    (data) => api.post(`/api/phonegroup/i/create`, data),
    {
      onSuccess: (res) => {
        setPhoneGroupCsv(null);
        setData({ name: "", description: "" });
        navigate(-1);
      },
    }
  );

  const { mutate: editPhonegroup } = useMutation(
    (formData) => {
      return api.put(`api/phonegroup/i/${id}`, formData);
    },
    {
      onSuccess: (res) => {
        //
        navigate(-1);
      },
    }
  );

  useQuery(
    `phonegroup-details_${id}`,
    () => {
      if (id) {
        return api
          .get(`/api/phonegroup/i/details/${id}`)
          .then((response) => response.data);
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setData({
            name: data.name,
            description: data.description,
          });
        }
      },
    }
  );

  const processFileData = (fileData) => {
    return new Promise((resolve, reject) => {
      const parser = parse({ delimiter: "," });
      let phoneNumbersColumnIndex = -1;
      let rowNumber = 0;

      parser.on("data", (data) => {
        rowNumber++;

        if (rowNumber > 65001) reject("Phone number counts exceeds 65,000");

        if (phoneNumbersColumnIndex === -1) {
          // Search for the column that starts with 'phone_numbers' in the first row
          phoneNumbersColumnIndex = data.findIndex((value) =>
            value.startsWith("phone_numbers")
          );

          if (phoneNumbersColumnIndex !== 0) {
            reject("1st column of your file must be 'phone_numbers' column");
            return;
          }
        } else {
          const phoneNumber = data[phoneNumbersColumnIndex];

          if (!phoneNumber.startsWith("959")) {
            reject(`Invalid value in row ${rowNumber}. Expected '959' prefix.`);
            return;
          }
        }
      });

      parser.on("end", () => resolve(true));

      parser.on("error", (error) => reject(error));

      parser.write(fileData);
      parser.end();
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        px: { xs: 2, lg: 5 },
        py: 2,
        gap: 2,
      }}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "flex-start", pb: 1 }}>
          <BackButton backFunction={() => navigate(-1)} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {isUpdateForm ? "Edit" : "Add New"} Phone Group
          </Typography>
        </Box>
        <Typography variant="subtitle2" color="text.secondary">
          Unleash the power of instant connection through our phone number
          group.
        </Typography>
      </Box>
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          color: "secondary.light",
        }}
      >
        Name
      </Typography>
      {/* Conditionally render name field based on the form type */}
      {isUpdateForm ? (
        <TextField
          value={data.name}
          onChange={handleOnChange}
          name="name"
          size="small"
          sx={{
            width: {
              lg: "30%",
              xs: "100%",
            },
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />
      ) : (
        <TextField
          required
          name="name"
          value={data.name}
          onChange={handleOnChange}
          size="small"
          sx={{
            width: {
              lg: "30%",
              xs: "100%",
            },
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />
      )}

      <Typography
        variant="subtitle2"
        fontWeight="bold"
        sx={{
          color: "secondary.light",
        }}
      >
        Description
      </Typography>
      {/* Conditionally render description field based on the form type */}
      {isUpdateForm ? (
        <TextField
          value={data.description}
          onChange={handleOnChange}
          name="description"
          size="small"
          sx={{
            width: {
              lg: "30%",
              xs: "100%",
            },
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />
      ) : (
        <TextField
          name="description"
          value={data.description}
          onChange={handleOnChange}
          size="small"
          sx={{
            width: {
              lg: "30%",
              xs: "100%",
            },
            border: "none",
            borderRadius: 1,
            "& .MuiInputBase-root": {
              backgroundColor: "#F0F0F0",
              borderRadius: 1,
              outline: "none",
              "&:hover": {
                backgroundColor: "#F0F0F0",
              },
            },
          }}
        />
      )}

      {/* Conditionally render phone number section based on the form type */}
      {!isUpdateForm && (
        <>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{
              color: "secondary.light",
            }}
          >
            Phone Number
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              padding: "10px",
              borderColor: "#fde89d",
              bgcolor: "#fff3cd",
              display: "flex",
              gap: "0.5rem",
              marginBottom: 2,
              width: "100%",
            }}
          >
            <ErrorOutlineOutlinedIcon sx={{ color: "primary.main" }} />
            <Typography variant="subtitle2" sx={{ color: "#757575" }}>
              Phone numbers should start from the 2nd row of the CSV file.
              &nbsp;
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "blue",
                }}
                onClick={() =>
                  exportFromJSON({
                    data: [
                      { phone_numbers: "959760614842" },
                      { phone_numbers: "959691002712" },
                      { phone_numbers: "959950242785" },
                      { phone_numbers: "959266550050" },
                    ],
                    fileName: "phonegroup_template",
                    exportType: exportFromJSON.types.csv,
                  })
                }
              >
                Download Template
              </span>
            </Typography>
          </Paper>

          <DragAndDropUpload
            types={["text/csv"]}
            selectedFile={phoneGroupCsv}
            handleFileSelect={setPhoneGroupCsv}
          />
        </>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: { xs: "center", md: "flex-start" },
          gap: 2,
        }}
      >
        <LoadingButton
          loading={isLoading}
          variant="contained"
          size="small"
          disableElevation
          onClick={handleSubmit}
        >
          {isUpdateForm ? "Update" : "Save"}{" "}
        </LoadingButton>
        <Button
          variant="outlined"
          size="small"
          disableElevation
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default CreateNewPhoneGroup;
