import React, { useCallback, useMemo, useState } from "react";
import { useAxios } from "../../hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ComfirmDialog from "../common/ConfirmDialog";
import { getDateTime } from "../../utilities/getDateTime";
import { useParams } from "react-router-dom";
import NoRowOverlay from "../common/NoRowOverlay";

const CompanyPhonegroupList = ({ handleOnSelecting }) => {
  const api = useAxios({ autoSnackbar: true });
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [phoneToDeleteId, setPhoneToDeleteId] = useState(null);

  const { data: res, isLoading } = useQuery(
    `phonegroup-list_active_${id}`,
    () => {
      return api.get(`/api/phonegroup/i/list/${id}`);
    }
  );

  const { mutate: deleteNumber } = useMutation(
    (phoneToDeleteId) => {
      return api.delete(`/api/phonegroup/i/remove/${phoneToDeleteId}/${id}`);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`phonegroup-list_active_${id}`);
      },
    }
  );

  const handleDeleteConfirmation = () => {
    deleteNumber(phoneToDeleteId);
  };

  const handleOpenDeleteDialog = useCallback(() => {
    setOpenDeleteDialog((prev) => !prev);
  }, []);

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 1,
        minWidth: 100,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "created_by",
        headerName: "Created By",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => {
              setPhoneToDeleteId(params.row.phone_group_access_id);
              handleOpenDeleteDialog();
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>Remove</Typography>
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data ?? []}
        loading={isLoading}
        checkboxSelection
        onSelectionModelChange={handleOnSelecting}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
      />
      <ComfirmDialog
        open={openDeleteDialog}
        handleClose={handleOpenDeleteDialog}
        handleOnConfirm={handleDeleteConfirmation}
      />
    </>
  );
};

export default CompanyPhonegroupList;
