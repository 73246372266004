import { useState, useMemo, React, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { useAxios } from "../../hooks";
// import ComfirmDialog from "../common/ConfirmDialog";
import { getDateTime } from "../../utilities/getDateTime";
import { Button, Typography } from "@mui/material";
import NoRowOverlay from "../common/NoRowOverlay";

export default function RejectedList({ handleOnSelecting }) {
  const api = useAxios({ autoSnackbar: true });
  // const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(25);

  // const [open, setOpen] = useState(false);
  // const [data, setData] = useState({ viberprofile_id: "", action: "" });

  // const handleOpen = useCallback(() => {
  //   setOpen((prev) => !prev);
  // }, []);

  const { data: res, isLoading } = useQuery(
    `viberprofile-list_rejected_?page=${page}&rowsPerPage=${rowsPerPage}`,
    () => {
      return api.get(
        `api/viberprofile/i/list/REJECTED/p?page=${page}&rowsPerPage=${rowsPerPage}`
      );
    }
  );

  // const { mutate } = useMutation(
  //   () => {
  //     return api.put(
  //       `/api/viberprofile/i/status/${data.viberprofile_id}?application_status=${data.action}`
  //     );
  //   },
  //   {
  //     onSuccess: (res) => {
  //       queryClient.invalidateQueries(
  //         `viberprofile-list_rejected_?page=${page}&rowsPerPage=${rowsPerPage}`
  //       );
  //     },
  //   }
  // );

  const downloadDocs = useCallback(
    (company_name, viberprofile_id) => async () => {
      const res = await api.get(
        `/api/viberprofile/i/docs/${viberprofile_id}/download`,
        {
          responseType: "blob",
        }
      );
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: "application/zip",
        })
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = `${company_name}.zip`;

      a.click();
      URL.revokeObjectURL(url);
    },
    [api]
  );

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
      {
        field: "company",
        headerName: "Company Name",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => params.row.company.name,
        renderCell: (params) => params.name,
      },
      {
        field: "reason",
        headerName: "Reject Reason",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) =>
          params.row?.ViberProfileApplicationStatus?.reason || "-",
        renderCell: (params) => params?.reason || "-",
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => params.row.company.email,
        renderCell: (params) => params.email,
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 150,
        flex: 0.5,
        valueGetter: (params) => params.row.company.phone_number,
        renderCell: (params) => params.phone_number,
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 100,
        flex: 1,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        flex: 1,
        renderCell: (params) => (
          // <Button
          //   variant="outlined"
          //   color="success"
          //   size="small"
          //   onClick={() => {
          //     setData({
          //       viberprofile_id: params.id,
          //       action: "ACCEPTED",
          //     });
          //     handleOpen();
          //   }}
          // >
          //   <Typography sx={{ fontSize: "11px" }}>Accept</Typography>
          // </Button>
          <Button
            variant="outlined"
            color="info"
            size="small"
            onClick={downloadDocs(params.row.company.name, params.id)}
          >
            <Typography sx={{ fontSize: "11px" }}>Download Docs</Typography>
          </Button>
        ),
      },
    ];
  }, [downloadDocs]);

  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        // checkboxSelection
        // onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={rowsPerPage}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setrowsPerPage(v)}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
      />
      {/* <ComfirmDialog
        open={open}
        handleClose={handleOpen}
        handleClickOpen={handleOpen}
        handleOnConfirm={mutate}
      /> */}
    </>
  );
}
