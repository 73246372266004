import { useState, useMemo, React, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";
import { useAxios } from "../../hooks";
import { getDateTime } from "../../utilities/getDateTime";
import { Box } from "@mui/material";
import BindServiceIdDialog from "./BindServiceIdDialog";
import NoRowOverlay from "../common/NoRowOverlay";

export default function AcceptedList({ handleOnSelecting }) {
  const api = useAxios({ autoSnackbar: true });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(25);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ viberprofile_id: "", action: "" });

  const handleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const { data: res, isLoading } = useQuery(
    `viberprofile-list_accepted_?page=${page}&rowsPerPage=${rowsPerPage}`,
    () => {
      return api.get(
        `api/viberprofile/i/list/ACCEPTED/p?page=${page}&rowsPerPage=${rowsPerPage}`
      );
    }
  );

  const downloadDocs = useCallback(
    (company_name, viberprofile_id) => async () => {
      const res = await api.get(
        `/api/viberprofile/i/docs/${viberprofile_id}/download`,
        {
          responseType: "blob",
        }
      );
      const url = URL.createObjectURL(
        new Blob([res.data], {
          type: "application/zip",
        })
      );
      const a = document.createElement("a");
      a.href = url;
      a.download = `${company_name}.zip`;

      a.click();
      URL.revokeObjectURL(url);
    },
    [api]
  );

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", minWidth: 50, flex: 0.3 },
      {
        field: "company",
        headerName: "Company Name",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => params.row.company.name,
        renderCell: (params) => params.name,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => params.row.company.email,
        renderCell: (params) => params.email,
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 150,
        flex: 0.5,
        valueGetter: (params) => params.row.company.phone_number,
        renderCell: (params) => params.phone_number,
      },
      {
        field: "created_at",
        headerName: "Created At",
        minWidth: 150,
        flex: 0.8,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "updated_at",
        headerName: "Updated At",
        minWidth: 100,
        flex: 0.8,
        valueFormatter: (params) => getDateTime(params.value),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 250,
        flex: 1,
        renderCell: (params) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="outlined"
              color="success"
              size="small"
              onClick={() => {
                setData({
                  viberprofile_id: params.id,
                  action: "ACTIVE",
                });
                handleOpen();
              }}
            >
              <Typography sx={{ fontSize: "11px" }}>Activate</Typography>
            </Button>
            <Button
              variant="outlined"
              color="info"
              size="small"
              onClick={downloadDocs(params.row.company.name, params.id)}
            >
              <Typography sx={{ fontSize: "11px" }}>Download Docs</Typography>
            </Button>
          </Box>
        ),
      },
    ];
  }, [setData, handleOpen, downloadDocs]);

  return (
    <>
      <DataGrid
        columns={columns}
        rowCount={res?.data?.total ?? 0}
        rows={res?.data?.rows ?? []}
        loading={isLoading}
        pagination
        paginationMode="server"
        // checkboxSelection
        // onSelectionModelChange={handleOnSelecting}
        page={page}
        pageSize={rowsPerPage}
        onPageChange={(v) => setPage(v)}
        onPageSizeChange={(v) => setrowsPerPage(v)}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: NoRowOverlay,
        }}
      />
      <BindServiceIdDialog
        open={open}
        handleDialogOnClick={handleOpen}
        propData={data}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
}
