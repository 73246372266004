import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ViberprofileList from "./ViberprofileList";
// import ViberprofileDetails from "./ViberprofileDetails";

const Viberprofile = () => {
  return (
    <Routes>
      <Route path="" element={<ViberprofileList />} exact />
      {/* <Route path="details/:id" element={<ViberprofileDetails />} /> */}
      <Route path="*" element={<Navigate to={""} replace />} />
    </Routes>
  );
};

export default Viberprofile;
