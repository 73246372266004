import { Box } from "@mui/material";
import ReportAction from "../../components/reports/ReportAction";

export default function MonthlyReport() {
  return (
    <Box>
      <ReportAction />
    </Box>
  );
}
